<template>
  <ClientOnly>
    <form class="mt-6" @submit.prevent="onSubmit">
      <fieldset class="flex gap-4 flex-wrap">
        <div class="w-full">
          <BaseInput
            id="firstName"
            v-model="firstName"
            v-bind="firstNameProps"
            :label="t('first_name')"
            class="flex-grow"
            autocomplete="name"
            size="xl"
            dark
            :has-error="Boolean(errors.firstName)"
          />
          <BaseErrorBag
            :has-error="Boolean(errors.firstName)"
            is-dark
            class="mt-2"
          >
            {{ errors.firstName }}
          </BaseErrorBag>
        </div>
        <div class="w-full">
          <BaseInputPhone
            id="phoneNumber"
            v-model="phoneNumber"
            v-bind="phoneNumberProps"
            :label="t('phone_number')"
            :prefix-label="t('phone_prefix')"
            wrapper-class="flex-grow"
            autocomplete="mobile tel-extension"
            size="xl"
            dark
            :has-error="Boolean(errors.phoneNumber)"
          />
          <BaseErrorBag
            :has-error="Boolean(errors.phoneNumber)"
            is-dark
            class="mt-2"
          >
            {{ errors.phoneNumber }}
          </BaseErrorBag>
        </div>
      </fieldset>
      <fieldset class="my-6">
        <div class="flex gap-4 items-center">
          <BaseCheckbox
            id="terms"
            v-model="termsAndCons"
            v-bind="termsAndConsProps"
            :has-error="Boolean(errors.termsAndCons)"
            dark
          />
          <label for="terms" class="text-brand-white semibold">
            {{ termsAndConsTextChunks?.rest?.[0] || '-' }} <NuxtLink
              v-if="termsAndConsTextChunks?.interpolated"
              class="text-brand-white underline"
              :to="termsAndConsUrl"
              target="_blank"
            >{{ termsAndConsTextChunks?.interpolated || '-' }}</NuxtLink>
            {{ termsAndConsTextChunks?.rest?.[1] }}
          </label>
        </div>
        <BaseErrorBag
          :has-error="Boolean(errors.termsAndCons)"
          is-dark
          class="mt-2"
        >
          {{ errors.termsAndCons }}
        </BaseErrorBag>
      </fieldset>

      <slot name="controls" v-bind="{ loading: isSending }">
        <BaseButton
          type="submit"
          size="xl"
          variant="white"
          class="w-full"
          :disabled="isSending"
          :loading="isSending"
        >
          {{ ctaText }}
        </BaseButton>
      </slot>
    </form>
  </ClientOnly>

  <!-- <EmailSentSuccessModal
    v-if="sendIntent"
    ref="$successModal"
    :success-image="modalImageSrc"
  /> -->
</template>

<script setup lang="ts">
import { ref, computed, inject, type Ref } from 'vue';
import BaseCheckbox from '~/components/forms/BaseCheckbox.vue';
import BaseInput from '~/components/forms/BaseInput.vue';
import BaseButton from '~/components/BaseButton.vue';
import BaseInputPhone from '~/components/forms/BaseInputPhone.vue';
import { z } from 'zod';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { ALPHANUMERIC_REGEX, PHONE_REGEX } from '~/lib/constants';
import BaseErrorBag from './BaseErrorBag.vue';
import { useApi, useGTM } from '~/composables';
import { extractTextInterpolation } from '~/stores/helpers';
import { useI18n, useRouter } from '#imports';
import type { Analytics } from '~/types';
const webSettings = inject('settings') as Record<string, any>;

const props = withDefaults(defineProps<{
  ctaText: string
  modalImageSrc: string
  termsAndConsText: string
  termsAndConsUrl: string
  trackingEvent?: string
}>(), {
  trackingEvent: 'form_contact_submit',
});

const { t } = useI18n({
  messages: {
    es: {
      first_name: 'Nombre',
      phone_number: 'Núm. de teléfono',
      phone_prefix: 'Prefijo',
      first_name__error_r: 'Debes introducir tu nombre',
      first_name__error_m: 'Debes introducir un nombre válido',
      phone_number__error_r: 'Debes introducir un número de teléfono',
      phone_number__error_m: 'Debes introducir un número de teléfono válido',
      terms_and_cons__error: 'Debes aceptar los términos y condiciones',
    },
    en: {
      first_name: 'Full Name',
      phone_number: 'Phone Number',
      phone_prefix: 'Code',
      first_name__error_r: 'You must enter your name',
      first_name__error_m: 'You must enter a valid name',
      phone_number__error_r: 'You must enter a phone number',
      phone_number__error_m: 'You must enter a valid phone number',
      terms_and_cons__error: 'You must accept the terms and conditions',
    },
    pt: {
      first_name: 'Nome completo',
      phone_number: 'Número de telefone',
      phone_prefix: 'Prefixo',
      first_name__error_r: 'Deve introduzir o seu nome',
      first_name__error_m: 'Deve introduzir um nome válido',
      phone_number__error_r: 'Deve introduzir um número de telefone',
      phone_number__error_m: 'Deve introduzir um número de telefone válido',
      terms_and_cons__error: 'Deve aceitar os termos e condições',
    },
  },
});

export type ModalExposeTypes = {
  openModal: () => void;
  closeModal: () => void;
  isOpen: Ref<boolean>
};

// const EmailSentSuccessModal = defineAsyncComponent(() => import('~/components/modals/EmailSentSuccessModal.vue'));
const { sendContactLead } = useApi();

const validationSchema = toTypedSchema(
  z.object({
    firstName: z.string({ required_error: t('first_name__error_r') }).regex(new RegExp(ALPHANUMERIC_REGEX), { message: t('first_name__error_m') }),
    phoneNumber: z.string({ required_error: t('phone_number__error_r') }).regex(new RegExp(PHONE_REGEX), { message: t('phone_number__error_m') }),
    termsAndCons: z.boolean({ required_error: t('terms_and_cons__error') }).refine(val => val === true, {
      message: t('terms_and_cons__error'),
    }),
  }),
);

const { errors, defineField, handleSubmit, resetForm } = useForm({ validationSchema });

const $successModal = ref<ModalExposeTypes|null>(null);
const isSending = ref(false);
const sendIntent = ref(false);
const termsAndConsTextChunks = computed(() => extractTextInterpolation(props.termsAndConsText || ''));
const [firstName, firstNameProps] = defineField('firstName');
const [phoneNumber, phoneNumberProps] = defineField('phoneNumber');
const [termsAndCons, termsAndConsProps] = defineField('termsAndCons');
const { triggerEvent, triggerConversion } = useGTM();
const router = useRouter();

const onSubmit = handleSubmit(
  async (fields) => {
    sendIntent.value = true;

    try {
      isSending.value = true;
      const customer = {
        fullname: fields.firstName as string,
        phone: fields.phoneNumber as string,
        accepted_terms_and_cons: fields.termsAndCons as boolean,
      };

      await sendContactLead({
        customer,
      });

      if ($successModal.value) {
        $successModal.value.openModal();
      }

      triggerEvent(props.trackingEvent, {});
      const analytics = webSettings.analytics as Analytics;
      triggerConversion(analytics.g_ads, analytics.g_ads_conversion_id);

      await router.push('/thank-you');
      resetForm();
    } catch (error) {
      console.error('[ContactForm] error: ', error);
    } finally {
      isSending.value = false;
    }
  },
);

</script>
